import { useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/firestore";

const getHomepage = async () => {
  const query = firebase
    .firestore()
    .collection("homepages")
    .where("isActive", "==", true)
    .orderBy("lastupdate", "desc")
    .limit(1);

  const querySnapshot = await query.get();
  const doc = querySnapshot.docs[0];
  return { id: doc.id, ...doc.data() };
};

export function useHomePage(defaultData) {
  const [homepage, setHomepage] = useState(defaultData);

  useEffect(() => {
    getHomepage().then((page) => {
      setHomepage(page);
    });
  }, []);

  return homepage;
}

const getMeetings = async () => {
  const query = firebase.firestore().collection("meetings");
  const querySnapshot = await query.get();
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export function useMeetings(defaultData) {
  const [meetings, setMeetings] = useState(defaultData);

  useEffect(() => {
    getMeetings().then((page) => {
      setMeetings(page);
    });
  }, []);

  return meetings;
}

const getEnfancePage = async () => {
  const query = firebase
    .firestore()
    .collection("enfancePages")
    .where("isActive", "==", true)
    .orderBy("lastupdate", "desc")
    .limit(1);

  const querySnapshot = await query.get();
  const doc = querySnapshot.docs[0];
  return { id: doc.id, ...doc.data() };
};

export function useEnfancePage(defaultData) {
  const [enfancePage, setEnfancePage] = useState(defaultData);

  useEffect(() => {
    getEnfancePage().then((page) => {
      setEnfancePage(page);
    });
  }, []);

  return enfancePage;
}

const getAinesPage = async () => {
  const query = firebase
    .firestore()
    .collection("ainesPages")
    .where("isActive", "==", true)
    .orderBy("lastupdate", "desc")
    .limit(1);

  const querySnapshot = await query.get();
  const doc = querySnapshot.docs[0];
  return { id: doc.id, ...doc.data() };
};

export function useAinesPage(defaultData) {
  const [ainesPage, setAinesPage] = useState(defaultData);

  useEffect(() => {
    getAinesPage().then((page) => {
      setAinesPage(page);
    });
  }, []);

  return ainesPage;
}

const getMosaiquePage = async () => {
  const query = firebase
    .firestore()
    .collection("mosaiquePages")
    .where("isActive", "==", true)
    .orderBy("lastupdate", "desc")
    .limit(1);

  const querySnapshot = await query.get();
  const doc = querySnapshot.docs[0];
  return { id: doc.id, ...doc.data() };
};

export function useMosaiquePage(defaultData) {
  const [mosaiquePage, setMosaiquePage] = useState(defaultData);

  useEffect(() => {
    getMosaiquePage().then((page) => {
      setMosaiquePage(page);
    });
  }, []);

  return mosaiquePage;
}

const getAdosPage = async () => {
  const query = firebase
    .firestore()
    .collection("adosPages")
    .where("isActive", "==", true)
    .orderBy("lastupdate", "desc")
    .limit(1);

  const querySnapshot = await query.get();
  const doc = querySnapshot.docs[0];
  return { id: doc.id, ...doc.data() };
};

export function useAdosPage(defaultData) {
  const [adosPage, setAdosPage] = useState(defaultData);

  useEffect(() => {
    getAdosPage().then((page) => {
      setAdosPage(page);
    });
  }, []);

  return adosPage;
}

const getJeunessePage = async () => {
  const query = firebase
    .firestore()
    .collection("jeunessePages")
    .where("isActive", "==", true)
    .orderBy("lastupdate", "desc")
    .limit(1);

  const querySnapshot = await query.get();
  const doc = querySnapshot.docs[0];
  return { id: doc.id, ...doc.data() };
};

export function useJeunessePage(defaultData) {
  const [jeunessePage, setJeunessePage] = useState(defaultData);

  useEffect(() => {
    getJeunessePage().then((page) => {
      setJeunessePage(page);
    });
  }, []);

  return jeunessePage;
}

const getNousPage = async () => {
  const query = firebase
    .firestore()
    .collection("nousPages")
    .where("isActive", "==", true)
    .orderBy("lastupdate", "desc")
    .limit(1);

  const querySnapshot = await query.get();
  const doc = querySnapshot.docs[0];
  return { id: doc.id, ...doc.data() };
};

export function useNousPage(defaultData) {
  const [nousPage, setNousPage] = useState(defaultData);

  useEffect(() => {
    getNousPage().then((page) => {
      setNousPage(page);
    });
  }, []);

  return nousPage;
}


const getContactPage = async () => {
  const query = firebase
    .firestore()
    .collection("contactPages")
    .where("isActive", "==", true)
    .orderBy("lastupdate", "desc")
    .limit(1);

  const querySnapshot = await query.get();
  const doc = querySnapshot.docs[0];
  return { id: doc.id, ...doc.data() };
};

export function useContactPage(defaultData) {
  const [contactPage, setContactPage] = useState(defaultData);
  useEffect(() => {
    getContactPage().then((page) => {
      setContactPage(page);
    });
  }, []);
  return contactPage;
}

const getPasteurPage = async () => {
  const query = firebase
    .firestore()
    .collection("pasteurPages")
    .where("isActive", "==", true)
    .orderBy("lastupdate", "desc")
    .limit(1);

  const querySnapshot = await query.get();
  const doc = querySnapshot.docs[0];
  return { id: doc.id, ...doc.data() };
};

export function usePasteurPage(defaultData) {
  const [pasteurPage, setPasteurPage] = useState(defaultData);
  useEffect(() => {
    getPasteurPage().then((page) => {
      setPasteurPage(page);
    });
  }, []);
  return pasteurPage;
}

const PREACHES_BATCH_SIZE = 25
const getPreaches = async (cursor) => {
  var query = firebase
    .firestore()
    .collection("preaches")
    .where("isPublished", "==", true)
    .orderBy("date", "desc")

  if (cursor) {
    query = query.startAfter(cursor)
  }

  query = query.limit(PREACHES_BATCH_SIZE)

  const querySnapshot = await query.get();
  const newCursor = querySnapshot.empty ? null : querySnapshot.docs[querySnapshot.size - 1]
  const preaches = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  return { preaches, newCursor }
};

export function usePreaches(defaultData) {
  const [preaches, setPreaches] = useState(defaultData);
  const [cursor, setCursor] = useState(null)

  function loadMore() {
    getPreaches(cursor).then((result) => {
      setPreaches({
        moreAvailable: result.newCursor !== null,
        items: [
          ...preaches.items,
          ...result.preaches
        ]
      })
      setCursor(result.newCursor)
    })
  }

  useEffect(() => getPreaches().then(loadMore), []);

  return [preaches, loadMore];
}

const getPreachesPage = async () => {
  const query = firebase
    .firestore()
    .collection("preachesPages")
    .where("isActive", "==", true)
    .orderBy("lastupdate", "desc")
    .limit(1);

  const querySnapshot = await query.get();
  const doc = querySnapshot.docs[0];
  return { id: doc.id, ...doc.data() };
};

export function usePreachesPage(defaultData) {
  const [preachesPage, setPreachesPage] = useState(defaultData);
  useEffect(() => getPreachesPage().then(setPreachesPage), []);
  return preachesPage;
}

const getDonationPage = async () => {
  const query = firebase
    .firestore()
    .collection("donationPages")
    .where("isActive", "==", true)
    .orderBy("lastupdate", "desc")
    .limit(1);

  const querySnapshot = await query.get();
  const doc = querySnapshot.docs[0];
  return { id: doc.id, ...doc.data() };
};

export function useDonationPage(defaultData) {
  const [donationPage, setDonationPage] = useState(defaultData);
  useEffect(() => getDonationPage().then(setDonationPage), []);
  return donationPage;
}
